import * as React from 'react';

import MainLayout from '../components/Layout/MainLayout';
import HeaderSchedulePage from '../shared/Header/HeaderSchedulePage';
import MySEO from '../shared/MySEO';
import {
  ContentSection,
  NormalText,
  PageBanner,
  PageContainer,
  PageContent,
  SocialLink,
  SubTitle,
  Title
} from 'shared/PrivacyPolicy/Common';

const ChinhSachBaoMatPage = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister={false}
    >
      <MySEO
        title="Chính sách bảo mật | DOL English - IELTS Đình Lực"
        hidePostfix
        h1="Chính sách bảo mật"
        slug={location.pathname}
      />

      <PageContainer>
        <PageBanner
          breadcrumbLinks={[
            {
              text: 'DOL English',
              to: '/'
            },
            {
              text: 'Chính sách bảo mật',
              to: ''
            }
          ]}
          title="Chính sách bảo mật"
        />
        <PageContent>
          <ContentSection hasBottomDivider>
            <Title>Chúng tôi là ai?</Title>
            <NormalText>
              Địa chỉ trang web của chúng tôi là:{' '}
              <SocialLink href="https://www.dolenglish.com/" target="_blank">
                https://www.dolenglish.com/
              </SocialLink>
            </NormalText>
          </ContentSection>

          <ContentSection hasBottomDivider>
            <Title>Các loại dữ liệu thu thập</Title>
            <SubTitle>Tương tác</SubTitle>
            <NormalText>
              Khi khách truy cập và tương tác (thông qua các ứng dụng trên trang
              web), chúng tôi thu thập dữ liệu được hiển thị trong kết quả tương
              tác, cũng như địa chỉ IP của khách truy cập và chuỗi tác nhân
              người dùng trình duyệt để giúp phát hiện spam.
            </NormalText>
            <NormalText>
              Một chuỗi ẩn danh được tạo từ địa chỉ email của bạn (còn được gọi
              là mã băm) có thể được cung cấp cho dịch vụ Gravatar để xem bạn có
              đang sử dụng nó hay không. Chính sách bảo mật của dịch vụ Gravatar
              có tại đây:{' '}
              <SocialLink
                href="https://automattic.com/privacy/"
                target="_blank"
              >
                https://automattic.com/privacy/
              </SocialLink>
              . Sau khi phê duyệt bình luận của bạn, ảnh hồ sơ của bạn sẽ hiển
              thị công khai trong bối cảnh bình luận của bạn.
            </NormalText>
            <SubTitle>Phương tiện truyền thông</SubTitle>
            <NormalText>
              Nếu bạn tải hình ảnh lên trang web, bạn nên tránh tải lên hình ảnh
              có bao gồm dữ liệu vị trí nhúng (EXIF GPS). Khách truy cập trang
              web có thể tải xuống và trích xuất bất kỳ dữ liệu vị trí nào từ
              hình ảnh trên trang web.
            </NormalText>
            <SubTitle>Cookies</SubTitle>
            <NormalText>
              Nếu bạn để lại nhận xét trên trang web của chúng tôi, bạn có thể
              chọn lưu tên, địa chỉ email và trang web của bạn trong cookie. Đây
              là những điều thuận tiện cho bạn để bạn không phải điền lại thông
              tin chi tiết của mình khi bạn để lại bình luận khác. Những cookie
              này sẽ tồn tại trong một năm.
            </NormalText>
            <NormalText>
              Nếu bạn truy cập trang đăng nhập của chúng tôi, chúng tôi sẽ đặt
              một cookie tạm thời để xác định xem trình duyệt của bạn có chấp
              nhận cookie hay không. Cookie này không chứa dữ liệu cá nhân và sẽ
              bị loại bỏ khi bạn đóng trình duyệt của mình.
            </NormalText>
            <NormalText>
              Khi bạn đăng nhập, chúng tôi cũng sẽ thiết lập một số cookie để
              lưu thông tin đăng nhập và các lựa chọn hiển thị màn hình của bạn.
              Cookie đăng nhập tồn tại trong hai ngày và cookie tùy chọn màn
              hình tồn tại trong một năm. Nếu bạn chọn “Nhớ thông tin đăng
              nhập”, thông tin đăng nhập của bạn sẽ tồn tại trong hai tuần. Nếu
              bạn đăng xuất khỏi tài khoản của mình, cookie đăng nhập sẽ bị xóa.
            </NormalText>
            <NormalText>
              Nếu bạn chỉnh sửa hoặc xuất bản một bài báo, một cookie bổ sung sẽ
              được lưu trong trình duyệt của bạn. Cookie này không bao gồm dữ
              liệu cá nhân và chỉ cho biết ID bài đăng của bài viết bạn vừa
              chỉnh sửa. Nó sẽ hết hạn sau 1 ngày.
            </NormalText>
            <SubTitle>Nhúng nội dung từ các trang web khác</SubTitle>
            <NormalText>
              Các bài viết trên trang web này có thể bao gồm nội dung nhúng (ví
              dụ: video, hình ảnh, bài báo, v.v.). Nội dung được nhúng từ các
              trang web khác hoạt động theo cách giống hệt như khi khách truy
              cập đã truy cập trang web khác.
            </NormalText>
            <NormalText>
              Các trang web này có thể thu thập dữ liệu về bạn, sử dụng cookie,
              nhúng theo dõi bên thứ ba bổ sung và giám sát tương tác của bạn
              với nội dung được nhúng đó, bao gồm cả việc theo dõi tương tác của
              bạn với nội dung được nhúng nếu bạn có tài khoản và đăng nhập vào
              trang web đó.
            </NormalText>
          </ContentSection>

          <ContentSection hasBottomDivider>
            <Title>Chúng tôi lưu giữ dữ liệu của bạn trong bao lâu</Title>
            <NormalText>
              Nếu bạn để lại tương tác với hệ thống, siêu dữ liệu của nó sẽ được
              giữ lại vô thời hạn. Điều này giúp chúng tôi có thể tự động nhận
              ra và phê duyệt bất kỳ tương tác tiếp theo nào thay vì giữ chúng
              trong hàng đợi kiểm duyệt.
            </NormalText>
            <NormalText>
              Đối với những người dùng đăng ký trên trang web của chúng tôi (nếu
              có), chúng tôi cũng lưu trữ thông tin cá nhân mà họ cung cấp trong
              hồ sơ người dùng của họ.
            </NormalText>
            <NormalText>
              Tất cả người dùng có thể xem, chỉnh sửa hoặc xóa thông tin cá nhân
              của họ bất kỳ lúc nào (ngoại trừ họ không thể thay đổi tên người
              dùng của mình). Quản trị viên trang web cũng có thể xem và chỉnh
              sửa thông tin đó.
            </NormalText>
          </ContentSection>

          <ContentSection hasBottomDivider>
            <Title>Bạn có quyền gì đối với dữ liệu của mình</Title>
            <NormalText>
              Nếu bạn có tài khoản trên trang web này hoặc đã để lại nhận xét,
              bạn có thể yêu cầu nhận tệp đã xuất của dữ liệu cá nhân mà chúng
              tôi lưu giữ về bạn, bao gồm bất kỳ dữ liệu nào bạn đã cung cấp cho
              chúng tôi.
            </NormalText>
            <NormalText>
              Bạn cũng có thể yêu cầu chúng tôi xóa mọi dữ liệu cá nhân mà chúng
              tôi giữ về bạn. Điều này không bao gồm bất kỳ dữ liệu nào mà chúng
              tôi có nghĩa vụ lưu giữ cho các mục đích quản trị, pháp lý hoặc
              bảo mật.
            </NormalText>
          </ContentSection>

          <ContentSection>
            <Title>Nơi chúng tôi gửi dữ liệu của bạn</Title>
            <NormalText>
              Nhận xét của khách truy cập có thể được kiểm tra thông qua dịch vụ
              phát hiện spam tự động.
            </NormalText>
          </ContentSection>
        </PageContent>
      </PageContainer>
    </MainLayout>
  );
};

ChinhSachBaoMatPage.propTypes = {};

ChinhSachBaoMatPage.defaultProps = {};

export default React.memo(ChinhSachBaoMatPage);
