import * as React from 'react';
import PropTypes from 'prop-types';

import OutlineArrowChevronRight from '@dolstaff/shared/es/icons/OutlineArrowChevronRight';
import { Link } from 'gatsby';
import styled from 'styled-components';
import cl from 'classnames';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from '../../../style/colors-v2';
import { fromScreen } from 'utils/media-query/responsive.util';

export const BreadCrumb = React.memo(props => {
  const { links } = props;

  return (
    <Links className="breadcrumb">
      {links.map((link, idx) => {
        const isLast = idx === links.length - 1;
        return (
          <div
            key={link.text}
            className={cl('link', { 'tablet-hidden': link.tabletOnly })}
          >
            <Link to={isLast ? null : link.to} key={link.text}>
              <Typography variant="medium/14-20">{link.text}</Typography>
            </Link>
            {isLast ? null : (
              <OutlineArrowChevronRight size={20} color={colorsV2.primary100} />
            )}
          </div>
        );
      })}
    </Links>
  );
});

const Links = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content;
  justify-content: start;
  gap: 8px;
  .link {
    display: grid;
    grid-template-columns: max-content;
    grid-auto-flow: column;
    justify-content: start;
    gap: 8px;
    p {
      color: ${colorsV2.primary100};
    }
    &.tablet-hidden {
      display: none;
      ${fromScreen(776)} {
        display: grid;
      }
    }
  }
`;

const LinkType = PropTypes.shape({
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
});

BreadCrumb.propTypes = {
  links: PropTypes.arrayOf(LinkType)
};
