import React from 'react';

import styled, { css } from 'styled-components';

import { colorsV2 } from '../style/colors-v2';
import { deviceBreakpoints } from '../utils/hooks/useDeviceQuery.hook';

export const Section = styled.section.attrs({ className: 'section' })`
  /* to increate specificity */
  &.pure.pure {
    padding-top: 0;
    padding-bottom: 0;
  }

  overflow: hidden;
  position: relative;
  z-index: 0;
  padding-bottom: 46px;

  ${({ selfish }) =>
    selfish
      ? css`
          padding-top: 2em;
          padding-bottom: 2em;
        `
      : css`
          margin-top: 2em;
          margin-bottom: 2em;
        `}

  @media ${deviceBreakpoints.lg} {
    padding-bottom: 0;
  }

  @media screen and (min-width: 1024px) {
    overflow: unset;

    ${({ selfish }) =>
      selfish
        ? css`
            padding-top: 4em;
            padding-bottom: 4em;
          `
        : css`
            margin-top: 4em;
            margin-bottom: 4em;
          `}
  }

  @media screen and (min-width: 768px) {
    overflow: unset;

    ${({ selfish }) =>
      selfish
        ? css`
            padding-top: 32px;
            padding-bottom: 32px;
          `
        : css`
            margin-top: 32px;
            margin-bottom: 32px;
          `}
  }
`;

/* breakpoints
  - majoR: 320, 720, 1024
  - minor: 600, 800, 1280
*/
export const Container = styled.div.attrs({ className: 'container' })`
  /* there is always a gap between screen and content for both side */
  /* default is mobile */
  width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;

  @media screen and ${deviceBreakpoints.largeMobile} {
    max-width: calc(425px - 32px);
  }

  @media screen and ${deviceBreakpoints.tablet} {
    width: calc(100% - 56px);
    max-width: calc(768px - 56px);
  }

  @media screen and ${deviceBreakpoints.desktop} {
    max-width: ${props => props.maxWidth || 1120}px;
  }

  img {
    max-width: 100%;
  }

  video {
    max-width: 100%;
    outline: none;
  }

  ${({ hasUnderline }) =>
    hasUnderline
      ? css`
          padding-bottom: 32px;
          border-bottom: 1px solid ${colorsV2.gray20};
        `
      : ''}
`;

/**
 * helper that wrap a component with section & container
 */
export const withMarketingSection =
  (Component, sectionProps = {}, containerProps = {}) =>
  props => {
    return (
      <Section {...sectionProps}>
        <Container {...containerProps}>
          <Component {...props} />
        </Container>
      </Section>
    );
  };
