import * as React from 'react';

import styled, { css } from 'styled-components';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';
import { withMarketingSection } from 'components/withMarketingSection';
import { deviceBreakpoints } from 'utils/hooks/useDeviceQuery.hook';
import { BreadCrumb } from 'shared/CourseLanding/Detail/BreadCrumb';

export const PageContainer = styled.div`
  .page-banner-container {
    background-color: ${colorsV2.black5};
    margin: 0;
    padding: 24px 0;
    .breadcrumb {
      a:last-child > p {
        color: ${colorsV2.gray100};
      }
    }
    .page-banner-content {
      display: grid;
      grid-auto-flow: row;
      grid-row-gap: 8px;
      @media ${deviceBreakpoints.md} {
        max-width: 712px;
      }
    }
  }

  .page-content-container {
    margin: 0;
    .page-content-content {
      .title {
        margin-bottom: 16px;
      }
      .sub-title {
        margin-bottom: 12px;
      }
      .normal-text {
        margin-bottom: 12px;
      }
      ul {
        padding-inline-start: 24px;
        margin-block-start: 0;
        margin-block-end: 0;
      }
    }
  }
  @media ${deviceBreakpoints.md} {
    .page-content-container {
      .page-content-content {
        max-width: 712px;
      }
    }
  }
`;
export const PageBanner = ({
  breadcrumbLinks,
  title,
  description,
  subText
}) => {
  const Component = withMarketingSection(
    () => (
      <>
        <BreadCrumb links={breadcrumbLinks} />
        {subText && <SubText>{subText}</SubText>}
        <Typography variant="semi-bold/24-30" className="title" as="h1">
          {title}
        </Typography>
        <NormalText>{description}</NormalText>
      </>
    ),
    { className: 'page-banner-container' },
    { className: 'page-banner-content' }
  );

  return <Component />;
};

export const PageContent = withMarketingSection(
  styled.div``,
  { className: 'page-content-container' },
  { className: 'page-content-content' }
);

export const ContentSection = styled.div`
  padding: 32px 0;

  ${props =>
    props.hasBottomDivider &&
    css`
      border-bottom: 1px solid ${colorsV2.gray40};
    `}
`;
export const Title = ({ children }) => (
  <Typography variant="semi-bold/24-30" className="title" as="h2">
    {children}
  </Typography>
);
export const SubTitle = ({ children }) => (
  <Typography variant="semi-bold/16-24" className="sub-title">
    {children}
  </Typography>
);
export const NormalText = ({ children }) => (
  <Typography variant="regular/14-20" className="normal-text">
    {children}
  </Typography>
);
export const SubText = ({ children }) => (
  <Typography
    variant="regular/12-20"
    className="sub-text"
    style={{ color: colorsV2.gray100 }}
  >
    {children}
  </Typography>
);
export const SocialLink = styled.a`
  color: ${colorsV2.blue100};
  border-bottom: 1.5px solid ${colorsV2.blue100};
  :hover {
    color: ${colorsV2.primary100};
    border-bottom: 1.5px solid ${colorsV2.primary100};
  }
`;
